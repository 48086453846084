import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { ParallaxProvider } from "react-scroll-parallax"
import FwIndustry from "../components/industries/full-width-industry"
import HeroSimple from "../components/shared/hero-simple"
import CaseStudiesSlider from "../components/shared/case-studies"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfHeroBlockSimpleLeft {
          heroblocksimpleleftIntroText
          heroblocksimpleleftTitle
        }
        cfIndustryParent {
          indParentOneFirstLine
          indParentOneSecondLine
          indParentOneTitle
          indParentOneActivateSubPageLink
          indParentOneBackgroundImage {
            mediaType
            mediaDetails {
              file
            }
          }
          indParentThreeFirstLine
          indParentThreeSecondLine
          indParentThreeTitle
          indParentThreeActivateSubPageLink
          indParentThreeBackgroundImage {
            mediaType
            mediaDetails {
              file
            }
          }
          indParentTwoFirstLine
          indParentTwoSecondLine
          indParentTwoTitle
          indParentTwoActivateSubPageLink
          indParentTwoBackgroundImage {
            mediaType
            mediaDetails {
              file
            }
          }
        }
        cfSharedCaseStudies {
          sharedCaseStudiesClients {
            sharedCaseStudiesClientsCaseStudy {
              ... on WPGraphQL_Page {
                title
                uri
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                    mimeType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const PageTemplateIndustriesParent = ({ data }) => {
  const page = data.wpgraphql.page

  return (
    <>
      <Layout>
        <HeroSimple pagedata={page.cfHeroBlockSimpleLeft} />
        <ParallaxProvider>
          <FwIndustry sendata={page} />
        </ParallaxProvider>
        <ParallaxProvider>
          <CaseStudiesSlider
            sendata={page.cfSharedCaseStudies.sharedCaseStudiesClients}
          />
        </ParallaxProvider>
      </Layout>
    </>
  )
}

export default PageTemplateIndustriesParent

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
