import React from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import { cloudImage } from "../utils.js"
import * as fwIndStyle from "./full-width-industry.module.css"

const FwIndustry = ({ sendata }) => {
  const theData = [
    {
      id: 1,
      imgURL: cloudImage("home-HealthTech_jwy2dg.jpg", "jpeg", 1920, true),
    },
    {
      id: 2,
      imgURL: cloudImage(
        "Industries_FinTech-2_nxbc7z_bfstgu.jpg",
        "jpeg",
        1920,
        true
      ),
    },
    {
      id: 3,
      imgURL: cloudImage("home-CleanTech_zblfeq.jpg", "jpeg", 1920, true),
    },
  ]

  return (
    <>
      <div
        className={`${fwIndStyle.hp_full_swiper} hp_full_swiper mb-5 overflow-hidden`}
      >
        <div
          className={`${fwIndStyle.swiper_slide} swiper-slide d-flex align-items-end align-items-md-center`}
        >
          <Parallax
            translateX={[5, 0]}
            translateY={[0, 0]}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            <div
              className={fwIndStyle.bg_imgs}
              style={{
                backgroundImage: theData[0].imgURL,
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
          </Parallax>
          <div className="container mb-5 mb-md-0 pb-5 pb-md-0">
            <div className="row z_index_top">
              <h2 className="h1">
                {sendata.cfIndustryParent.indParentOneTitle}
              </h2>
              <h3 className="h4 mt-3 mb-5">
                {sendata.cfIndustryParent.indParentOneFirstLine}{" "}
                <br className="d-none d-sm-block"></br>
                {sendata.cfIndustryParent.indParentOneSecondLine}
              </h3>
              {sendata.cfIndustryParent.indParentOneActivateSubPageLink !==
                true && (
                <Link to="/contact/">
                  <button
                    title="Let's talk"
                    className="ani_white_arrow color-white ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "white",
                    }}
                  >
                    Let's Talk
                  </button>
                </Link>
              )}
              {sendata.cfIndustryParent.indParentOneActivateSubPageLink ===
                true && (
                <Link
                  to="/industries/digital-health/"
                  title="Digital Health"
                  className="ani_grey_arrow color-white"
                >
                  Learn More
                </Link>
              )}
            </div>
          </div>
          <div className={fwIndStyle.full_swiper_mask}></div>
        </div>

        <div
          className={`${fwIndStyle.swiper_slide} swiper-slide d-flex align-items-end align-items-md-center`}
        >
          <Parallax
            translateX={[5, 0]}
            translateY={[0, 0]}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            <div
              className={fwIndStyle.bg_imgs}
              style={{
                backgroundImage: theData[1].imgURL,
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
          </Parallax>
          <div className="container mb-5 mb-md-0 pb-5 pb-md-0">
            <div className="row z_index_top">
              <h2 className="h1">
                {sendata.cfIndustryParent.indParentTwoTitle}
              </h2>
              <h3 className="h4 mt-3 mb-5">
                {sendata.cfIndustryParent.indParentTwoFirstLine}{" "}
                <br className="d-none d-sm-block"></br>
                {sendata.cfIndustryParent.indParentTwoSecondLine}
              </h3>
              {sendata.cfIndustryParent.indParentTwoActivateSubPageLink !==
                true && (
                <Link to="/contact/">
                  <button
                    title="Let's talk"
                    className="ani_white_arrow color-white ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "white",
                    }}
                  >
                    Let's Talk
                  </button>
                </Link>
              )}
              {sendata.cfIndustryParent.indParentTwoActivateSubPageLink ===
                true && (
                <Link
                  to="/industries/financial-services/"
                  title="Financial Services"
                  className="ani_grey_arrow color-white"
                >
                  Learn More
                </Link>
              )}
            </div>
          </div>
          <div className={fwIndStyle.full_swiper_mask}></div>
        </div>

        <div
          className={`${fwIndStyle.swiper_slide} swiper-slide d-flex align-items-end align-items-md-center`}
        >
          <Parallax
            translateX={[5, 0]}
            translateY={[0, 0]}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            <div
              className={fwIndStyle.bg_imgs}
              style={{
                backgroundImage: theData[2].imgURL,
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
          </Parallax>
          <div className="container mb-5 mb-md-0 pb-5 pb-md-0">
            <div className="row z_index_top">
              <h2 className="h1">
                {sendata.cfIndustryParent.indParentThreeTitle}
              </h2>
              <h3 className="h4 mt-3 mb-5">
                {sendata.cfIndustryParent.indParentThreeFirstLine}{" "}
                <br className="d-none d-sm-block"></br>
                {sendata.cfIndustryParent.indParentThreeSecondLine}
              </h3>
              {sendata.cfIndustryParent.indParentThreeActivateSubPageLink !==
                true && (
                <Link to="/contact/">
                  <button
                    title="Let's talk"
                    className="ani_white_arrow color-white ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "white",
                    }}
                  >
                    Let's Talk
                  </button>
                </Link>
              )}
              {sendata.cfIndustryParent.indParentThreeActivateSubPageLink ===
                true && (
                <Link
                  to="/industries/energy/"
                  title="Energy"
                  className="ani_grey_arrow color-white"
                >
                  Learn More
                </Link>
              )}
            </div>
          </div>
          <div className={fwIndStyle.full_swiper_mask}></div>
        </div>
      </div>
    </>
  )
}

export default FwIndustry
